import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Button,
  Box,
  Alert,
  CircularProgress,
  Paper
} from '@mui/material';

const RestoreVehicles = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState('');

  const handleRestore = async () => {
    setLoading(true);
    setError('');
    setSuccess(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/restore_vehicles`);
      setSuccess(response.data.message);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to restore vehicles from backup.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Restaurar Veículos do Backup
        </Typography>
        <Typography variant="body1" gutterBottom>
          Se você carregou uma planilha incorretamente e deseja restaurar os dados do backup, clique no botão abaixo.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRestore}
            disabled={loading}
          >
            Restaurar do Backup
          </Button>
        </Box>
        {loading && <CircularProgress sx={{ mt: 2 }} />}
        {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      </Paper>
    </Container>
  );
};

export default RestoreVehicles;
