import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  AppBar,
  Toolbar,
  Button,
  Box,
  TextField,
  MenuItem,
  Autocomplete,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Pagination,
  FormControlLabel,  // Adicionado
  Checkbox,            // Adicionado
} from '@mui/material';
import Financiamento from './Financiamento'; // Importe o novo componente
import SolicitarLaudo from './SolicitarLaudo'; // Importe o novo componente

const Client = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, name } = location.state || {};
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filters, setFilters] = useState({
    model: '',
    color: '',
    km: '',
    kmFilterType: 'below',
    fipe: '',
    fipeFilterType: 'below',
    margin: '',
    hasVideo: false,  // Inicializa como false
    sortBy: ''  // Inicializa com uma string vazia
  });
  const [models, setModels] = useState([]);
  const [colors, setColors] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVideoLink, setSelectedVideoLink] = useState('');
  const [page, setPage] = useState(1);
  const vehiclesPerPage = 100;
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [open, setOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');



  useEffect(() => {
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
        navigate('/login');
      }, 50000); // Tempo de exibição da janela de erro

      return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
    }
  }, [open, navigate]);

  const formatDateString = (dateString) => {
    // Convertendo a string em um objeto Date
    const date = new Date(dateString);
  
    // Verificando se a data é válida
    if (isNaN(date)) {
      console.error("Data inválida recebida:", dateString);
      return "Data inválida";
    }
  
    // Adicionando um dia à data
    date.setDate(date.getDate() + 1);
  
    // Formatando a data para o formato dd/mm/aaaa
    return date.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };
  
  

  const checkAuth = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/check_auth`, { withCredentials: true });
  
        // Verificando a resposta para saber se o usuário está autenticado
        if (!response.data.isAuthenticated) {
            console.log("Resposta de autenticação recebida:", response.data);
  
            // Exibir a mensagem exata recebida do backend
            setErrorMessage(response.data.message || "Autenticação falhou. Por favor, tente novamente.");
            setOpen(true);
        } else if (response.data.is_admin) {
            navigate('/login');
        } else {
            // Exibindo a data de expiração bruta para depuração
            console.log("Data de expiração recebida:", response.data.dt_exp);
  
            // Formatar a data se necessário
            const formattedDate = formatDateString(response.data.dt_exp); 
            setExpirationDate(formattedDate);
  
            // Carregar veículos e filtros
            fetchVehicles();
            fetchFiltersData();
        }
    } catch (error) {
        console.error('Error checking authentication:', error);
  
        // Exibir a mensagem de erro somente se ela existir na resposta do erro
        const errorMessage = error.response?.data?.message || 'Sua assinatura expirou, entre em contato com nossa equipe para renova-la.';
        setErrorMessage(errorMessage);
        setOpen(true);
    }
  };
  





// Função para fechar a janela de erro e redirecionar para a página de login
const handleCloseError = () => {
  setOpen(false);
  navigate('/login');
};














  const fetchVehicles = async () => {
    try {
      const response = await axios.get(`/api/client_vehicles/${userId}`, { withCredentials: true });
      const shuffledVehicles = response.data.sort(() => 0.5 - Math.random());
      setVehicles(shuffledVehicles);
      setFilteredVehicles(shuffledVehicles);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const fetchFiltersData = async () => {
    try {
      const [modelsRes, colorsRes] = await Promise.all([
        axios.get(`/api/models`, { withCredentials: true }),
        axios.get(`/api/colors`, { withCredentials: true })
      ]);

      setModels(modelsRes.data);
      setColors(colorsRes.data);
    } catch (error) {
      console.error('Error fetching filter data:', error);
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = vehicles.filter((vehicle) => {
        const { model, color, km, kmFilterType, fipe, fipeFilterType, margin, hasVideo } = filters;
        return (
          (!model || vehicle.model.toLowerCase().includes(model.toLowerCase())) &&
          (!color || vehicle.color.toLowerCase().includes(color.toLowerCase())) &&
          (!km || (kmFilterType === 'below' ? vehicle.km <= parseInt(km) : vehicle.km >= parseInt(km))) &&
          (!fipe || (fipeFilterType === 'below' ? vehicle.fipe <= parseFloat(fipe) : vehicle.fipe >= parseFloat(fipe))) &&
          (!margin || vehicle.margin >= parseFloat(margin)) &&
          (!hasVideo || (vehicle.video_link && vehicle.video_link !== 'SL')) // Filtra por veículos com vídeo
        );
      });
  
      // Ordenar os veículos com base na seleção
      switch (filters.sortBy) {
        case 'km':
          filtered.sort((a, b) => a.km - b.km);
          break;
        case 'fipe':
          filtered.sort((a, b) => a.fipe - b.fipe);
          break;
        case 'margin':
          filtered.sort((a, b) => b.margin - a.margin);
          break;
        default:
          break;
      }
  
      setFilteredVehicles(filtered);
    };
  
    applyFilters();
  }, [filters, vehicles]);
  

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLogout = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };

  const goToClientPanel = () => {
    navigate('/client-panel', { state: { userId, name } });
  };

  const handleRequestInfo = async (vehicle) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehicles/${vehicle.id}`);
      setSelectedVehicle(response.data);
  
      let videoLink = '';
      if (response.data.video_link) {
        // Verifica se o link é uma URL curta do YouTube ou uma URL completa
        const youtubeUrlPattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i;
        const match = response.data.video_link.match(youtubeUrlPattern);
        if (match && match[1]) {
          videoLink = `https://www.youtube.com/embed/${match[1]}`;
        }
      }
  
      setSelectedVideoLink(videoLink);
      console.log("Vehicle Details:", response.data); // Log para verificar os dados recebidos
      console.log("Video Link:", videoLink); // Log para verificar o link do vídeo
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching vehicle details:', error);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedVehicle(null);
    setSelectedVideoLink('');
  };

  const handleSimularFinanciamento = (price) => {
    navigate('/financiamento', { state: { price } });
  };



  const vehiclesToDisplay = filteredVehicles.slice((page - 1) * vehiclesPerPage, page * vehiclesPerPage);

  return (
    
    
    <Container maxWidth="lg">
      {/* Janela de erro */}
      <Dialog
  open={open}
  onClose={handleCloseError}
>
  <DialogTitle>Erro de Autenticação</DialogTitle>
  <DialogContent>
    <Typography>{errorMessage}</Typography>
  </DialogContent>
  <DialogActions>
    <Button
      onClick={() => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=5511966185997&text=Ol%C3%A1%20solicito%20a%20ativa%C3%A7%C3%A3o%20ou%20renova%C3%A7%C3%A3o%20da%20assinatura`;
        window.open(whatsappUrl, '_blank');
      }}
      color="primary"
    >
      Contatar Suporte via WhatsApp
    </Button>
    <Button onClick={handleCloseError} color="primary">
      Fechar
    </Button>
  </DialogActions>
</Dialog>
{!open && (
      <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Bem-vindo, {name}
          </Typography>
          <Button color="inherit" onClick={goToClientPanel}>
            Painel do Cliente
          </Button>          
          <Button color="inherit" onClick={() => navigate('/financiamento')}>
            Simular Financiamento
          </Button>     
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>          
        </Toolbar>
      </AppBar>
      <SolicitarLaudo />
      <Routes>
        <Route path="/financiamento" element={<Financiamento />} />
        <Route
          path="/"
          element={
            <Box sx={{ my: 4 }}>
              <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: 'primary.main', borderRadius: '10px', border: '1px solid #ddd', color:'white' }}>
  <Box sx={{ textAlign: 'center', mb: 2 }}>
    <Typography variant="h4" component="h2" fontWeight="bold" gutterBottom>
      Veículos LOCALIZA-SP
    </Typography>
    <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 'medium' }}>
      Data de Expiração da Assinatura: {expirationDate}
    </Typography>
  </Box>
  <Box sx={{ mb: 2 }}>
    <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
      Nossa plataforma oferece uma seleção de veículos da localiza-sp, perfeita para revendedores que desejam atualizar seus estoques ou exibir veiculos para seus clientes.
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
      Procure o veiculo ideal para seu cliente aqui utilizando os filtros abaixo
    </Typography>
    <Typography component="h4" sx={{ fontSize: '1.4rem', lineHeight: 2.6, textAlign: 'center', margim: '50px' }}>
      Gostou do veiculo? 
    </Typography>
    <Typography component="h4" sx={{ fontSize: '1.4rem', lineHeight: 1.6, textAlign: 'center', margim: '50px' }}>
     Faça uma visita presencial sem compromisso.
    </Typography>    
  </Box>
  <Box sx={{ textAlign: 'center' }}>    
  </Box>
</Paper>

<Paper elevation={3} sx={{ p: 2, mb: 4 }}>
  <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '2.0rem' }}>
    Filtros
  </Typography>
  <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '1.0rem' }}>
    Utilize os filtros abaixo para encontrar o veículo que atenda às suas necessidades.
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4 }}>
    <Autocomplete
      options={models}
      getOptionLabel={(option) => option}
      onChange={(event, newValue) => setFilters({ ...filters, model: newValue })}
      renderInput={(params) => <TextField {...params} label="Modelo" sx={{ minWidth: 250 }} />}
    />
    <Autocomplete
      options={colors}
      getOptionLabel={(option) => option}
      onChange={(event, newValue) => setFilters({ ...filters, color: newValue })}
      renderInput={(params) => <TextField {...params} label="Cor" sx={{ minWidth: 200 }} />}
    />
    <TextField
      label="KM"
      name="km"
      value={filters.km}
      onChange={handleFilterChange}
      sx={{ minWidth: 100 }}
    />
    <TextField
      label="Tipo KM"
      name="kmFilterType"
      value={filters.kmFilterType}
      onChange={handleFilterChange}
      select
      sx={{ minWidth: 100 }}
    >
      <MenuItem value="below">Abaixo</MenuItem>
      <MenuItem value="above">Acima</MenuItem>
    </TextField>
    <TextField
      label="FIPE"
      name="fipe"
      value={filters.fipe}
      onChange={handleFilterChange}
      sx={{ minWidth: 100 }}
    />
    <TextField
      label="Tipo FIPE"
      name="fipeFilterType"
      value={filters.fipeFilterType}
      onChange={handleFilterChange}
      select
      sx={{ minWidth: 100 }}
    >
      <MenuItem value="below">Abaixo</MenuItem>
      <MenuItem value="above">Acima</MenuItem>
    </TextField>
    <TextField
      label="Desconto"
      name="margin"
      value={filters.margin}
      onChange={handleFilterChange}
      sx={{ minWidth: 100 }}
    />

    {/* Checkbox para filtrar veículos com vídeo */}
    <Box sx={{ minWidth: 200 }}>
  <FormControlLabel
    control={
      <Checkbox
        checked={filters.hasVideo}
        onChange={(e) => {
          const updatedFilters = { ...filters, hasVideo: e.target.checked };
          console.log('Checkbox State:', updatedFilters.hasVideo); // Depuração
          setFilters(updatedFilters);
        }}
      />
    }
    label="Exibir apenas veículos com vídeo"
  />
</Box>


    {/* Campo de seleção para ordenar veículos */}
    <TextField
      label="Ordenar por"
      name="sortBy"
      value={filters.sortBy}
      onChange={handleFilterChange}
      select
      sx={{ minWidth: 200 }}
    >
      <MenuItem value="km">KM</MenuItem>
      <MenuItem value="fipe">FIPE</MenuItem>
      <MenuItem value="margin">Desconto</MenuItem>
    </TextField>
  </Box>

  <Typography variant="h6" component="h2" gutterBottom>
    Sua pesquisa retornou {filteredVehicles.length} veículos
  </Typography>
</Paper>

              
              <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: 'primary.main', borderRadius: '10px', border: '1px solid #ddd', color:'white' }}>
                <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '2.4rem', lineHeight: 1.6, textAlign: 'center', margim: '50px' }}>
                Avisos Importantes!
                </Typography>
                <Typography>
                  <Typography sx={{textAlign:'center'}}>Sempre consulte a disponibilidade da Placa antes de realizar a visita presencial </Typography>
                  <Typography sx={{textAlign:'center'}}>As imagens dos veiculos são apenas ilustrativas</Typography>
                  <Typography sx={{textAlign:'center'}}>A imagem real do veiculo pode ser vista atraves do video ou de visita presencial.</Typography>
                  <Typography sx={{textAlign:'center'}}>É necessario CNPJ de revenda ou locaçao para aquisiçao dos veiculos.</Typography>
                </Typography>                
              </Paper>
              <Paper elevation={3} sx={{ p: 2, mb: 4 , fontSize:'2.4rem'}}>
                <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '2.4rem', lineHeight: 1.6, textAlign: 'center', margim: '50px' }}>
                  Veículos disponiveis
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '1.4rem', lineHeight: 1.6, textAlign: 'center', margim: '50px' }}>
                  Alameda Rio Negro, 585, Alphaville - SP
                </Typography>
              </Paper>

              <Grid container spacing={4}>
  {vehiclesToDisplay.map((vehicle) => (
    <Grid item key={vehicle.id} xs={12} sm={6} md={4}>
      <Card sx={{ maxWidth: 345, height: '100%', position: 'relative' }}>
        {/* Exibe o retângulo verde se o link do vídeo não estiver vazio */}
        {vehicle.video_link && vehicle.video_link !== 'SL' && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'green',
              color: 'white',
              padding: '5px 10px',
              borderTopLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              zIndex: 1,
            }}
          >
            Vídeo disponível
          </Box>
        )}
        <CardMedia
          component="img"
          height="200"
          image={`${vehicle.image}`}
          alt={vehicle.model}
          sx={{ objectFit: 'cover', height: '200px' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {vehicle.model}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>ID:</strong> {vehicle.id}
            <br />
            <strong>Placa:</strong> {vehicle.plate}
            <br />
            <strong>Ano:</strong> {vehicle.year_mod}
            <br />
            <strong>KM:</strong> {vehicle.km}
            <br />
            <strong>Cor:</strong> {vehicle.color}
            <br />
            <strong>Valor FIPE R$:</strong> {vehicle.fipe}
            <br />
            <strong>Preço Cliente R$:</strong> {vehicle.price_client}
            <br />
            <strong>Desconto R$:</strong> {vehicle.margin}
            <br />
            <strong>Cidade:</strong> {vehicle.city}
            <br />
            <strong>Estado:</strong> {vehicle.state}
            <br />
          </Typography>
          {vehicle.video_link && vehicle.video_link !== 'SL' && (
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleRequestInfo(vehicle)}
          >
            Ver Vídeo
          </Button>
          )}
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>


              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  count={Math.ceil(filteredVehicles.length / vehiclesPerPage)}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </Box>
          }
        />
      </Routes>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Ver Detalhes do Veículo</DialogTitle>
        <DialogContent>
          {selectedVideoLink ? (
            <Box sx={{ width: '100%', height: 'auto' }}>
              <iframe
                width="560"
                height="315"
                src={selectedVideoLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          ) : (
            <Typography>Link do vídeo não disponível</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      </>
)}
    </Container>
  );
};

export default Client;

