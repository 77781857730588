import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Button, TextField, AppBar, Toolbar, Paper, Grid } from '@mui/material';

const ClientPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, name } = location.state || {};
  const [userInfo, setUserInfo] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    } else {
      fetchUserInfo();
    }
  }, [userId, navigate]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user_info/${userId}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('As senhas não correspondem');
      return;
    }
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/change_password/${userId}`, { password: newPassword });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage('Error changing password');
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1);  // Navega para a página anterior
  };

  return (
    <Container maxWidth="md">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Painel do Cliente
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ my: 4 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Informações do Cliente
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Nome: {userInfo.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Telefone: {userInfo.phone_number}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Email: {userInfo.email}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Alterar Senha
          </Typography>
          <TextField
            label="Nova Senha"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            sx={{ my: 2 }}
          />
          <TextField
            label="Confirmar Nova Senha"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            sx={{ my: 2 }}
          />
          <Button variant="contained" color="info" onClick={handlePasswordChange}>
            Alterar Senha
          </Button>
          {message && <Typography color="success" sx={{ my: 2 }}>{message}</Typography>}
        </Paper>
        <Box sx={{ mt: 4 }}>
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ClientPanel;
