// SolicitarLaudo.js
import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const SolicitarLaudo = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSolicitarLaudo = () => {
    const whatsappNumber = '5511966185997'; // Número do WhatsApp com DDI e DDD
    const message = 'Olá gostaria de solicitar o serviço de laudo cautelar';
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ mt: 1 , margim : '0px', padding: '8px' }}>
        Solicitar Laudo Cautelar
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Solicitação de Laudo Cautelar</DialogTitle>
        <DialogContent>
          <Typography>
            Verifique estrutura, restrições, débitos, sinistros, passagens por leilão através do Laudo cautelar.
          </Typography>
          <Typography>
            - O Laudo Cautelar é um serviço adicional com custo de R$ 200,00 por veículo.
          </Typography>
          <Typography>
            - O Laudo é realizado por empresa credenciada terceirizada e será disponibilizado para o cliente assim que finalizado.
          </Typography>
          <Typography>
            - Para solicitar o Laudo, clique no botão abaixo e informe a placa do Veículo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSolicitarLaudo} color="primary">
            Solicitar Laudo
          </Button>
          <Button onClick={handleClose} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SolicitarLaudo;
