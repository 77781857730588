import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Box, Toolbar, AppBar
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const GerenciarUsuarios = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);  // Estado para armazenar o usuário a ser excluído
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    name: '',
    phone_number: '',
    email: '',
    dt_exp: ''
  });
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/check_auth`, { withCredentials: true });
      if (!response.data.isAuthenticated || !response.data.is_admin) {
        navigate('/login');
      } else {
        fetchUsers();
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      navigate('/login');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleCreateUser = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users`, newUser, { withCredentials: true });
      setNewUser({ username: '', password: '', name: '', phone_number: '', email: '', dt_exp: '' });
      setOpenCreateDialog(false);
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleEditUser = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/${editingUser.id}`, editingUser, { withCredentials: true });
      setEditingUser(null);
      setOpenEditDialog(false);
      fetchUsers();
    } catch (error) {
      console.error('Error editing user:', error);
    }
  };

  const handleDeleteUser = async () => {
    if (userToDelete.is_admin) {
      alert('Não é possível excluir um usuário administrador');
      setOpenDeleteConfirm(false);
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userToDelete.id}`, { withCredentials: true });
      setOpenDeleteConfirm(false);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const openEditDialogForUser = (user) => {
    setEditingUser(user);
    setOpenEditDialog(true);
  };

  const openDeleteConfirmDialog = (user) => {
    setUserToDelete(user);
    setOpenDeleteConfirm(true);
  };

  const handleLogout = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" onClick={() => navigate('/admin')}>Voltar</Button>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>

      <Box sx={{ padding: '20px' }}>
        <Button variant="contained" color="primary" onClick={() => setOpenCreateDialog(true)}>
          Adicionar Usuário
        </Button>
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Senha</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Data Expiração</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.password}</TableCell> {/* Senha mascarada */}
                  <TableCell>{user.phone_number}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.dt_exp}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openEditDialogForUser(user)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => openDeleteConfirmDialog(user)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog para criar um novo usuário */}
        <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
          <DialogTitle>Criar Novo Usuário</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nome"
              type="text"
              fullWidth
              value={newUser.name}
              onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Senha"
              type="password"
              fullWidth
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Telefone"
              type="text"
              fullWidth
              value={newUser.phone_number}
              onChange={(e) => setNewUser({ ...newUser, phone_number: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Data de Expiração"
              type="date"
              fullWidth
              value={newUser.dt_exp}
              onChange={(e) => setNewUser({ ...newUser, dt_exp: e.target.value })}
              InputLabelProps={{ shrink: true }} // Para garantir que a label do input de data seja legível
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCreateDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleCreateUser} color="primary">
              Criar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para editar um usuário */}
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
          <DialogTitle>Editar Usuário</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nome"
              type="text"
              fullWidth
              value={editingUser?.name || ''}
              onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={editingUser?.username || ''}
              onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
            />            
            <TextField
              margin="dense"
              label="Telefone"
              type="text"
              fullWidth
              value={editingUser?.phone_number || ''}
              onChange={(e) => setEditingUser({ ...editingUser, phone_number: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={editingUser?.email || ''}
              onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Data de Expiração"
              type="date"
              fullWidth
              value={editingUser?.dt_exp || ''}
              onChange={(e) => setEditingUser({ ...editingUser, dt_exp: e.target.value })}
              InputLabelProps={{ shrink: true }} // Para garantir que a label do input de data seja legível
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleEditUser} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para confirmar a exclusão de um usuário */}
        <Dialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <p>Você tem certeza que deseja excluir este usuário? Esta ação não pode ser desfeita.</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteUser} color="secondary">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default GerenciarUsuarios;

