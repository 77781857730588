import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Client from './components/Client';
import Admin from './components/Admin';
import ClientPanel from './components/ClientPanel';
import Signup from './components/Signup';
import Financiamento from './components/Financiamento';
import PadronizarPlanilha from './components/PadronizarPlanilha';
import GerenciadorVeicular from './components/GerenciadorVeicular';
import GerenciarUsuarios from './components/gerenciarusuarios';
import RestoreVehicles from './components/RestoreVehicles';

function App() {
  useEffect(() => {
    document.title = "HR Veículos"; // Altere aqui para o título desejado
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/client" element={<Client />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/client-panel" element={<ClientPanel />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/financiamento" element={<Financiamento />} />
        <Route path="/padronizar-planilha" element={<PadronizarPlanilha />} />
        <Route path="/gerenciador-veicular" element={<GerenciadorVeicular />} />
        <Route path="/gerenciar-usuarios" element={<GerenciarUsuarios />} /> 
        <Route path="/restore-vehicles" element={<RestoreVehicles />} />
      </Routes>
    </Router>
  );
}

export default App;
