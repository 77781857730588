import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography, Paper, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import logo from './img/logo.png'; // Certifique-se de que o caminho para a logo está correto

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100vh',
  backgroundColor: '#1c1c1c',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  padding: '0 20px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: '40px',
  textAlign: 'center',
  maxWidth: '400px',
  width: '100%',
  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.2)',
  borderRadius: '15px',
  backgroundColor: '#f4f4f4',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const Logo = styled('img')(({ theme }) => ({
  width: '180px',
  height: 'auto',
  marginBottom: '30px',
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    marginBottom: '15px',
  },
}));

const MotivationalText = styled(Typography)(({ theme }) => ({
  marginBottom: '20px',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#333',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '15px',
  },
}));

const FeatureList = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  fontSize: '1rem',
  color: '#555',
  '& > p': {
    margin: '5px 0',
    fontWeight: '500',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false); // Estado para controlar o diálogo de erro
  const [errorMessage, setErrorMessage] = useState(''); // Adicionando o estado para a mensagem de erro

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Verifica se os campos estão vazios
    if (!formData.username || !formData.password) {
      setError('Todos os campos são obrigatórios.');
      setOpen(true); // Abre o diálogo de erro
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, { withCredentials: true });
      if (response.data.error) {
        setError(response.data.error);
        setOpen(true); // Abre o diálogo em caso de erro
      } else {
        const { id, is_admin, name } = response.data;
        if (is_admin) {
          navigate('/admin', { state: { userId: id, name } });
        } else {
          navigate('/client', { state: { userId: id, name } });
        }
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Sua assinatura expirou, entre em contato com nossa equipe para renová-la.';
      setErrorMessage(errorMessage); // Define a mensagem de erro
      setOpen(true); // Abre o diálogo em caso de erro
    }
  };

  const handleCloseError = () => {
    setOpen(false);
    navigate('/login');
  };

  return (
    <StyledContainer maxWidth="lg">
      {!open && ( // Oculta o restante do conteúdo se o diálogo estiver aberto
        <>
          <Logo src={logo} alt="Logo da Empresa" />
          <LoginPaper>
            <MotivationalText>
              Portal do Revendedor
            </MotivationalText>
            <FeatureList>
              <Typography>Acesse centenas de veículos</Typography>
              <Typography>Venda sem ter em estoque</Typography>
              <Typography>Margens para revendedores</Typography>
              <Typography>Veiculos Abaixo da FIPE</Typography>
              <Typography>Cancele quando quiser</Typography>
            </FeatureList>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nome de usuário"
                name="username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Senha"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
              <Box sx={{ mt: 2 }}>
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Entrar
                </Button>
              </Box>
            </form>
            <Box sx={{ mt: 2 }}>
              <Button variant="outlined" color="primary" fullWidth onClick={() => navigate('/signup')} sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Criar conta
              </Button>
            </Box>
          </LoginPaper>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleCloseError}
      >
        <DialogTitle>Erro de Autenticação</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage || error}</Typography> {/* Corrigido para exibir a mensagem de erro correta */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const whatsappUrl = `https://api.whatsapp.com/send?phone=5511966185997&text=Ol%C3%A1%20solicito%20a%20ativa%C3%A7%C3%A3o%20ou%20renova%C3%A7%C3%A3o%20da%20assinatura`;
              window.open(whatsappUrl, '_blank');
            }}
            color="primary"
          >
            Contatar Suporte via WhatsApp
          </Button>
          <Button onClick={handleCloseError} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default Login;
