import React, { useState } from 'react';
import { Button, Container, Typography, Box, Paper, Alert } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const PadronizarPlanilha = () => {
  const [files, setFiles] = useState([]);
  const [outputData, setOutputData] = useState([]);
  const [debugMessages, setDebugMessages] = useState([]);

  const requiredFields = [
    'Placa', 'Loja', 'Modelo', 'AnoFab', 'AnoMod', 'Km', 'Cor',
    'Fipe', 'Margem', 'GanhoIPVA', 'PrecoCliente', 'Cidade', 'Estado'
  ];

  const handleFileChange = (e) => {
    setFiles(e.target.files);
    setDebugMessages(['Arquivos selecionados: ' + Array.from(e.target.files).map(file => file.name).join(', ')]);
  };

  const processFiles = () => {
    let combinedData = [];
    let newDebugMessages = ['Iniciando o processamento dos arquivos...'];

    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Padronizar dados
        const standardizedSheet = standardizeData(sheet);
        combinedData = combinedData.concat(standardizedSheet);

        newDebugMessages.push(`Arquivo ${file.name} processado com sucesso.`);
        setDebugMessages([...newDebugMessages]);
      };
      reader.readAsBinaryString(file);
    });

    setOutputData(combinedData);
    newDebugMessages.push('Todos os arquivos foram processados e concatenados.');
    setDebugMessages([...newDebugMessages]);
  };

  const standardizeData = (data) => {
    return data.map((row) => {
      let standardizedRow = {};

      requiredFields.forEach((field) => {
        standardizedRow[field] = row[field] !== undefined ? row[field] : 0;
      });

      return standardizedRow;
    });
  };

  const downloadTemplate = () => {
    const templateData = [
      {
        Placa: 'ABC1234',
        Loja: 'Loja Exemplo',
        Modelo: 'Modelo Exemplo',
        AnoFab: 2020,
        AnoMod: 2021,
        Km: 15000,
        Cor: 'Preto',
        Fipe: 50000.0,
        Margem: 10.0,
        GanhoIPVA: 2000.0,
        PrecoCliente: 45000.0,
        Cidade: 'São Paulo',
        Estado: 'SP',
      },
    ];

    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Template');

    XLSX.writeFile(wb, 'Template_Veiculos.xlsx');
  };

  const downloadStandardizedFile = () => {
    const ws = XLSX.utils.json_to_sheet(outputData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Veiculos_Padronizados');

    XLSX.writeFile(wb, 'Veiculos_Padronizados.xlsx');
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Padronizador de Planilhas
        </Typography>
        <Box sx={{ mt: 2 }}>
          <input
            type="file"
            multiple
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="upload-button"
          />
          <label htmlFor="upload-button">
            <Button variant="contained" component="span">
              Escolher Arquivos
            </Button>
          </label>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={processFiles} disabled={!files.length}>
            Processar Arquivos
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="secondary" onClick={downloadTemplate}>
            Baixar Template
          </Button>
        </Box>
        {outputData.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="success" onClick={downloadStandardizedFile}>
              Baixar Arquivo Padronizado
            </Button>
          </Box>
        )}
        {debugMessages.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Depuração:</Typography>
            {debugMessages.map((msg, index) => (
              <Alert key={index} severity="info" sx={{ mt: 1 }}>
                {msg}
              </Alert>
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default PadronizarPlanilha;
