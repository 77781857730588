import React, { useState, useEffect } from 'react';
import { Button, Container, TextField, Typography, Box, Paper, Checkbox, FormControlLabel } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const Financiamento = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [valorVeiculo, setValorVeiculo] = useState(location.state?.price || '');
  const [entrada, setEntrada] = useState('');
  const [taxaJuros, setTaxaJuros] = useState('');
  const [prazo, setPrazo] = useState('');
  const [parcela, setParcela] = useState(null);
  const [valorTotalPago, setValorTotalPago] = useState(null);
  const [totalJuros, setTotalJuros] = useState(null);

  const [valorFinanciadoTotal, setValorFinanciadoTotal] = useState('');
  const [incluirCadastro, setIncluirCadastro] = useState(false);
  const [incluirSeguro, setIncluirSeguro] = useState(false);
  const [incluirContrato, setIncluirContrato] = useState(false);
  const [incluirIOF, setIncluirIOF] = useState(false);
  const [incluirAvaliacao, setIncluirAvaliacao] = useState(false);

  useEffect(() => {
    calcularValores();
  }, [valorVeiculo, entrada, incluirCadastro, incluirSeguro, incluirContrato, incluirIOF, incluirAvaliacao]);

  const calcularValores = () => {
    let valorFinanciado = parseFloat(valorVeiculo) - parseFloat(entrada);

    if (incluirCadastro) valorFinanciado += 1000;
    if (incluirAvaliacao) valorFinanciado += 700;
    if (incluirSeguro) valorFinanciado += parseFloat(valorVeiculo) * 0.04;
    if (incluirContrato) valorFinanciado += 300;

    if (incluirIOF) valorFinanciado += valorFinanciado * 0.03;

    setValorFinanciadoTotal(valorFinanciado.toFixed(2));
  };

  const calcularParcela = () => {
    const PV = parseFloat(valorFinanciadoTotal);
    const i = parseFloat(taxaJuros) / 100;
    const n = parseInt(prazo, 10);

    if (!isNaN(PV) && !isNaN(i) && !isNaN(n) && n > 0) {
      const valorParcela = (PV * i) / (1 - Math.pow(1 + i, -n));
      const valorTotal = valorParcela * n;
      const jurosTotal = valorTotal - PV;
      setParcela(valorParcela.toFixed(2));
      setValorTotalPago(valorTotal.toFixed(2));
      setTotalJuros(jurosTotal.toFixed(2));
    } else {
      alert('Por favor, insira valores válidos.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Simulador de Financiamento
        </Typography>
        <TextField
          label="Valor do Veículo"
          value={valorVeiculo}
          onChange={(e) => setValorVeiculo(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Entrada"
          value={entrada}
          onChange={(e) => setEntrada(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Taxa de Juros Mensal (%)"
          value={taxaJuros}
          onChange={(e) => setTaxaJuros(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Prazo (meses)"
          value={prazo}
          onChange={(e) => setPrazo(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControlLabel
          control={<Checkbox checked={incluirCadastro} onChange={(e) => setIncluirCadastro(e.target.checked)} />}
          label="Incluir Abertura de Cadastro"
        />
        <FormControlLabel
          control={<Checkbox checked={incluirAvaliacao} onChange={(e) => setIncluirAvaliacao(e.target.checked)} />}
          label="Incluir Avaliação de Bem"
        />
        <FormControlLabel
          control={<Checkbox checked={incluirSeguro} onChange={(e) => setIncluirSeguro(e.target.checked)} />}
          label="Incluir Seguro Prestamista"
        />
        <FormControlLabel
          control={<Checkbox checked={incluirContrato} onChange={(e) => setIncluirContrato(e.target.checked)} />}
          label="Incluir Registro de Contrato"
        />
        <FormControlLabel
          control={<Checkbox checked={incluirIOF} onChange={(e) => setIncluirIOF(e.target.checked)} />}
          label="Incluir IOF"
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Valor Financiado Total: R$ {valorFinanciadoTotal}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={calcularParcela}>
            Calcular
          </Button>
          <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
            Voltar
          </Button>
        </Box>
        {parcela && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">
              Valor da Parcela: R$ {parcela}
            </Typography>
            <Typography variant="h6">
              Valor Total Pago: R$ {valorTotalPago}
            </Typography>
            <Typography variant="h6">
              Total de Juros Pagos: R$ {totalJuros}
            </Typography>
            <Typography variant="h6">
              Valor Total Pago com Entrada: R$ {(parseFloat(entrada) + parseFloat(valorTotalPago)).toFixed(2)}
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default Financiamento;
