import React, { useState, useEffect } from 'react';
import { useNavigate , Link }  from 'react-router-dom';
import axios from 'axios';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Typography, Box, Alert, Paper
} from '@mui/material';
import { styled } from '@mui/system';
import RestoreVehicles from './RestoreVehicles';

const StyledContainer = styled(Box)({
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
});

const Title = styled(Typography)({
  marginBottom: '16px',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#333',
});

const SectionTitle = styled(Typography)({
  marginBottom: '8px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#333',
});

const StyledPaper = styled(Paper)({
  padding: '32px',
  marginTop: '16px',
  marginBottom: '16px',
  width: '100%',
  maxWidth: '600px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  border: '1px solid #ddd',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledButton = styled(Button)({
  marginTop: '16px',
  width: '200px',
  backgroundColor: '#007bff',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const LogoutButton = styled(Button)({
  marginTop: '16px',
  width: '200px',
  backgroundColor: '#d63384',
  color: 'white',
  '&:hover': {
    backgroundColor: '#b0296b',
  },
});

const Admin = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchPlate, setSearchPlate] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searchError, setSearchError] = useState('');
  const [userId, setUserId] = useState(null);
  const [editVehicle, setEditVehicle] = useState({
    plate: '', store: '', model: '', year_fab: '', year_mod: '', km: '', color: '', fipe: '', margin: '', gain_ipva: '', price_client: '', city: '', state: '', image: null, video_link: ''
  });
  const [notificationMessage, setNotificationMessage] = useState('');

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/check_auth`, { withCredentials: true });
      if (!response.data.isAuthenticated || !response.data.is_admin) {
        navigate('/login');
      } else {
        setUserId(response.data.user_id);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      navigate('/login');
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      console.error('No file selected');
      alert('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    axios.post(`${process.env.REACT_APP_API_URL}/upload_vehicles`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('File uploaded successfully', response.data);
        setUploadMessage(response.data.message);
        if (response.data.errors) {
          setUploadMessage(prev => `${prev}. Errors: ${response.data.errors.join(', ')}`);
        }
      })
      .catch(error => {
        console.error('There was an error uploading the file!', error);
      });
  };

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handleAddChange = (event) => {
    const { name, value } = event.target;
    setEditVehicle({ ...editVehicle, [name]: value });
  };

  const handleAddSubmit = () => {
    const formData = new FormData();
    for (const key in editVehicle) {
      if (editVehicle[key] !== null && editVehicle[key] !== undefined) {
        formData.append(key, editVehicle[key]);
      }
    }

    axios.post(`${process.env.REACT_APP_API_URL}/vehicles`, formData)
      .then(response => {
        console.log(response.data);
        handleAddClose();
      })
      .catch(error => {
        console.error('There was an error adding the vehicle!', error);
      });
  };

  const handleChangePasswordOpen = () => {
    setOpenChangePassword(true);
  };

  const handleChangePasswordClose = () => {
    setOpenChangePassword(false);
    setNewPassword('');
    setConfirmPassword('');
    setPasswordMessage('');
  };

  const handleChangePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/change_password/${userId}`, { password: newPassword }, { withCredentials: true });
      setPasswordMessage(response.data.message);
    } catch (error) {
      console.error('There was an error changing the password!', error);
      setPasswordMessage('Error changing password');
    }
  };

  const handleLogout = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };

  const handleSearchIdChange = (event) => {
    setSearchId(event.target.value);
  };

  const handleSearchPlateChange = (event) => {
    setSearchPlate(event.target.value);
  };

  const handleSearchById = async () => {
    setSearchResult(null);
    setSearchError('');
    if (!searchId) {
      setSearchError('Please enter a vehicle ID');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehicles/${searchId}`);
      setSearchResult(response.data);
    } catch (error) {
      console.error('There was an error searching for the vehicle!', error);
      setSearchError('Vehicle not found');
    }
  };

  const handleSearchByPlate = async () => {
    setSearchResult(null);
    setSearchError('');
    if (!searchPlate) {
      setSearchError('Please enter a vehicle plate');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehicles/plate/${searchPlate}`);
      setSearchResult(response.data);
    } catch (error) {
      console.error('There was an error searching for the vehicle!', error);
      setSearchError('Vehicle not found');
    }
  };

  const handleEditOpen = () => {
    setEditVehicle({
      ...searchResult
    });
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setEditVehicle({
      plate: '', store: '', model: '', year_fab: '', year_mod: '', km: '', color: '', fipe: '', margin: '', gain_ipva: '', price_client: '', city: '', state: '', image: null, video_link: ''
    });
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditVehicle({ ...editVehicle, [name]: value });
  };

  const handleEditSubmit = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/vehicles/${searchResult.id}`, editVehicle);
      setSearchResult(null);
      setSearchError('');
      handleEditClose();
      alert('Vehicle updated successfully');
    } catch (error) {
      console.error('There was an error updating the vehicle!', error);
      alert('Error updating vehicle');
    }
  };

  const handleNotify = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/notify_users`, {}, { withCredentials: true });
      setNotificationMessage(response.data.message);
    } catch (error) {
      console.error('There was an error sending notifications!', error);
      setNotificationMessage('Error sending notifications');
    }
  };

  const handleNavigateToUserManagement = () => {
    navigate('/gerenciar-usuarios');
  };

  

  return (
    <StyledContainer>
      <Title>Painel do Administrador</Title>
      <StyledPaper>
        <SectionTitle>Sistema</SectionTitle>
        <LogoutButton variant="contained" onClick={handleLogout}>
          Logout
        </LogoutButton>
        <StyledButton variant="contained" onClick={handleChangePasswordOpen}>
          Mudar Senha
        </StyledButton>
      </StyledPaper>
      <StyledPaper>
        <SectionTitle>Atualização Database</SectionTitle>
        <input type="file" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
        <StyledButton variant="contained" onClick={handleUpload}>
          Upar Planilha
        </StyledButton>
        <StyledButton variant="contained" onClick={handleAddOpen}>
          Inserir Veículo
        </StyledButton>        
        {uploadMessage && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {uploadMessage}
          </Alert>
        )}
      </StyledPaper>
      <StyledPaper>
      <StyledPaper>
        <SectionTitle>Gerenciamento de Veículos</SectionTitle>
        <StyledButton variant="contained" onClick={() => navigate('/gerenciador-veicular')}>
          Gerenciar Veículos
        </StyledButton>
      </StyledPaper>
      <Box sx={{ mt: 4 }}>
  <Link to="/restore-vehicles">
    <Button variant="contained" color="primary">
      Restaurar Veículos do Backup
    </Button>
  </Link>
</Box>

      <StyledPaper>
        <Button variant="contained" color="primary" onClick={handleNavigateToUserManagement}>
          Gerenciar Usuários
        </Button>
        {/* Outros botões e funcionalidades existentes */}
      </StyledPaper>
      
        <SectionTitle>Localizar Veículo por ID</SectionTitle>
        <TextField
          margin="dense"
          name="searchId"
          label="ID do Veículo"
          type="text"
          fullWidth
          value={searchId}
          onChange={handleSearchIdChange}
        />
        <StyledButton variant="contained" onClick={handleSearchById}>
          Pesquisar por ID
        </StyledButton>
        {searchError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {searchError}
          </Alert>
        )}
        {searchResult && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Typography><strong>ID:</strong> {searchResult.id}</Typography>
            <Typography><strong>Placa:</strong> {searchResult.plate}</Typography>
            <Typography><strong>Loja:</strong> {searchResult.store}</Typography>
            <Typography><strong>Modelo:</strong> {searchResult.model}</Typography>
            <Typography><strong>Ano Fab:</strong> {searchResult.year_fab}</Typography>
            <Typography><strong>Ano Mod:</strong> {searchResult.year_mod}</Typography>
            <Typography><strong>KM:</strong> {searchResult.km}</Typography>
            <Typography><strong>Cor:</strong> {searchResult.color}</Typography>
            <Typography><strong>FIPE:</strong> {searchResult.fipe}</Typography>
            <Typography><strong>Margem:</strong> {searchResult.margin}</Typography>
            <Typography><strong>Ganho IPVA:</strong> {searchResult.gain_ipva}</Typography>
            <Typography><strong>Preço Cliente:</strong> {searchResult.price_client}</Typography>
            <Typography><strong>Cidade:</strong> {searchResult.city}</Typography>
            <Typography><strong>Estado:</strong> {searchResult.state}</Typography>
            <Typography><strong>Link do Vídeo:</strong> {searchResult.video_link}</Typography>
            {searchResult.image && <img src={searchResult.image} alt={searchResult.model} style={{ width: '100%', maxWidth: '400px', marginTop: '10px' }} />}
            <StyledButton variant="contained" onClick={handleEditOpen}>
              Editar Veículo
            </StyledButton>
          </Box>
        )}
      </StyledPaper>
      <StyledPaper>
        <SectionTitle>Localizar Veículo por Placa</SectionTitle>
        <TextField
          margin="dense"
          name="searchPlate"
          label="Placa do Veículo"
          type="text"
          fullWidth
          value={searchPlate}
          onChange={handleSearchPlateChange}
        />
        <StyledButton variant="contained" onClick={handleSearchByPlate}>
          Pesquisar por Placa
        </StyledButton>
        {searchError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {searchError}
          </Alert>
        )}
        {searchResult && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Typography><strong>ID:</strong> {searchResult.id}</Typography>
            <Typography><strong>Placa:</strong> {searchResult.plate}</Typography>
            <Typography><strong>Loja:</strong> {searchResult.store}</Typography>
            <Typography><strong>Modelo:</strong> {searchResult.model}</Typography>
            <Typography><strong>Ano Fab:</strong> {searchResult.year_fab}</Typography>
            <Typography><strong>Ano Mod:</strong> {searchResult.year_mod}</Typography>
            <Typography><strong>KM:</strong> {searchResult.km}</Typography>
            <Typography><strong>Cor:</strong> {searchResult.color}</Typography>
            <Typography><strong>FIPE:</strong> {searchResult.fipe}</Typography>
            <Typography><strong>Margem:</strong> {searchResult.margin}</Typography>
            <Typography><strong>Ganho IPVA:</strong> {searchResult.gain_ipva}</Typography>
            <Typography><strong>Preço Cliente:</strong> {searchResult.price_client}</Typography>
            <Typography><strong>Cidade:</strong> {searchResult.city}</Typography>
            <Typography><strong>Estado:</strong> {searchResult.state}</Typography>
            <Typography><strong>Link do Vídeo:</strong> {searchResult.video_link}</Typography>
            {searchResult.image && <img src={searchResult.image} alt={searchResult.model} style={{ width: '100%', maxWidth: '400px', marginTop: '10px' }} />}
            <StyledButton variant="contained" onClick={handleEditOpen}>
              Editar Veículo
            </StyledButton>
          </Box>
        )}
      </StyledPaper>
      <StyledPaper>
        <SectionTitle>Notificações</SectionTitle>
        <StyledButton variant="contained" onClick={handleNotify}>
          Notificar Atualização
        </StyledButton>
        {notificationMessage && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {notificationMessage}
          </Alert>
        )}
      </StyledPaper>

      <Dialog open={openAdd} onClose={handleAddClose}>
        <DialogTitle>Adicionar Veículo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="plate"
            label="Placa"
            type="text"
            fullWidth
            value={editVehicle.plate}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="store"
            label="Loja"
            type="text"
            fullWidth
            value={editVehicle.store}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="model"
            label="Modelo"
            type="text"
            fullWidth
            value={editVehicle.model}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="year_fab"
            label="Ano Fab"
            type="text"
            fullWidth
            value={editVehicle.year_fab}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="year_mod"
            label="Ano Mod"
            type="text"
            fullWidth
            value={editVehicle.year_mod}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="km"
            label="KM"
            type="text"
            fullWidth
            value={editVehicle.km}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="color"
            label="Cor"
            type="text"
            fullWidth
            value={editVehicle.color}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="fipe"
            label="FIPE"
            type="text"
            fullWidth
            value={editVehicle.fipe}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="margin"
            label="Margem"
            type="text"
            fullWidth
            value={editVehicle.margin}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="gain_ipva"
            label="Ganho IPVA"
            type="text"
            fullWidth
            value={editVehicle.gain_ipva}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="price_client"
            label="Preço Cliente"
            type="text"
            fullWidth
            value={editVehicle.price_client}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="city"
            label="Cidade"
            type="text"
            fullWidth
            value={editVehicle.city}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="state"
            label="Estado"
            type="text"
            fullWidth
            value={editVehicle.state}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            name="video_link"
            label="Link do Vídeo"
            type="text"
            fullWidth
            value={editVehicle.video_link}
            onChange={handleAddChange}
          />
          <input
            type="file"
            name="image"
            onChange={(event) => setEditVehicle({ ...editVehicle, image: event.target.files[0] })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddSubmit} color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEdit} onClose={handleEditClose}>
        <DialogTitle>Editar Veículo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="plate"
            label="Placa"
            type="text"
            fullWidth
            value={editVehicle.plate}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="store"
            label="Loja"
            type="text"
            fullWidth
            value={editVehicle.store}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="model"
            label="Modelo"
            type="text"
            fullWidth
            value={editVehicle.model}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="year_fab"
            label="Ano Fab"
            type="text"
            fullWidth
            value={editVehicle.year_fab}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="year_mod"
            label="Ano Mod"
            type="text"
            fullWidth
            value={editVehicle.year_mod}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="km"
            label="KM"
            type="text"
            fullWidth
            value={editVehicle.km}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="color"
            label="Cor"
            type="text"
            fullWidth
            value={editVehicle.color}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="fipe"
            label="FIPE"
            type="text"
            fullWidth
            value={editVehicle.fipe}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="margin"
            label="Margem"
            type="text"
            fullWidth
            value={editVehicle.margin}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="gain_ipva"
            label="Ganho IPVA"
            type="text"
            fullWidth
            value={editVehicle.gain_ipva}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="price_client"
            label="Preço Cliente"
            type="text"
            fullWidth
            value={editVehicle.price_client}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="city"
            label="Cidade"
            type="text"
            fullWidth
            value={editVehicle.city}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="state"
            label="Estado"
            type="text"
            fullWidth
            value={editVehicle.state}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="video_link"
            label="Link do Vídeo"
            type="text"
            fullWidth
            value={editVehicle.video_link}
            onChange={handleEditChange}
          />
          <input
            type="file"
            name="image"
            onChange={(event) => setEditVehicle({ ...editVehicle, image: event.target.files[0] })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default Admin;

 
