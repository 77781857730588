import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Button, Checkbox, Container, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, Select, MenuItem, Box, TextField
} from '@mui/material';

const GerenciadorVeicular = () => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [filter, setFilter] = useState('all');
  const [plateFilter, setPlateFilter] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editVehicle, setEditVehicle] = useState(null);
  const [editLink, setEditLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/check_auth`, { withCredentials: true });
      if (!response.data.isAuthenticated || !response.data.is_admin) {
        navigate('/login');
      } else {
        fetchVehicles();
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      navigate('/login');
    }
  };

  const fetchVehicles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehicles`, { withCredentials: true });
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedVehicles(checked ? filteredVehicles.map((v) => v.id) : []);
  };

  const handleSelectVehicle = (id) => {
    setSelectedVehicles((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((vid) => vid !== id) : [...prevSelected, id]
    );
  };

  const handleHideVehicles = async () => {
    try {
      await Promise.all(
        selectedVehicles.map((id) => axios.put(`${process.env.REACT_APP_API_URL}/vehicles/hide/${id}`, {}, { withCredentials: true }))
      );
      fetchVehicles();
      setSelectedVehicles([]);
      setSelectAll(false);
      setSnackbarMessage('Vehicles hidden successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error hiding vehicles:', error);
      setSnackbarMessage('Error hiding vehicles');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleUnhideVehicles = async () => {
    try {
      await Promise.all(
        selectedVehicles.map((id) => axios.put(`${process.env.REACT_APP_API_URL}/vehicles/unhide/${id}`, {}, { withCredentials: true }))
      );
      fetchVehicles();
      setSelectedVehicles([]);
      setSelectAll(false);
      setSnackbarMessage('Vehicles unhidden successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error unhiding vehicles:', error);
      setSnackbarMessage('Error unhiding vehicles');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteVehicles = async () => {
    try {
      await Promise.all(
        selectedVehicles.map((id) => axios.delete(`${process.env.REACT_APP_API_URL}/vehicles/delete/${id}`, { withCredentials: true }))
      );
      fetchVehicles();
      setSelectedVehicles([]);
      setSelectAll(false);
      setSnackbarMessage('Vehicles deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error deleting vehicles:', error);
      setSnackbarMessage('Error deleting vehicles');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleLogout = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1); // Voltar para a página anterior
  };

  const handleEditOpen = (vehicle) => {
    setEditVehicle(vehicle);
    setEditLink(vehicle.video_link);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditVehicle(null);
    setEditLink('');
  };

  const handleEditSubmit = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/vehicles/${editVehicle.id}`, { video_link: editLink }, { withCredentials: true });
      fetchVehicles();
      setEditDialogOpen(false);
      setSnackbarMessage('Link atualizado com sucesso');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating video link:', error);
      setSnackbarMessage('Erro ao atualizar o link do vídeo');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    if (filter === 'all') return true;
    if (filter === 'hidden') return vehicle.hidden;
    if (filter === 'visible') return !vehicle.hidden;
    return true;
  }).filter((vehicle) => {
    if (!plateFilter) return true;
    return vehicle.plate.toLowerCase().includes(plateFilter.toLowerCase());
  });

  const totalVehicles = vehicles.length;
  const hiddenVehicles = vehicles.filter((v) => v.hidden).length;
  const visibleVehicles = totalVehicles - hiddenVehicles;

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gerenciador de Veículos
        </Typography>
        <Box>
          <Button variant="contained" color="primary" onClick={handleBack} sx={{ mr: 2 }}>
            Voltar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Box>
      <Typography variant="body1">
        Existem {totalVehicles} veículos no DB.
      </Typography>
      <Typography variant="body1">
        Existem {hiddenVehicles} veículos escondidos.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Existem {visibleVehicles} veículos não escondidos.
      </Typography>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Button variant="contained" color="secondary" onClick={handleHideVehicles} disabled={selectedVehicles.length === 0}>
          Esconder Selecionados
        </Button>
        <Button variant="contained" color="primary" onClick={handleUnhideVehicles} disabled={selectedVehicles.length === 0} sx={{ ml: 2 }}>
          Reexibir Selecionados
        </Button>
        <Button variant="contained" color="error" onClick={handleOpenDeleteDialog} disabled={selectedVehicles.length === 0} sx={{ ml: 2 }}>
          Excluir Selecionados
        </Button>
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          inputProps={{ 'aria-label': 'Selecionar Todos' }}
          sx={{ ml: 2 }}
        />
        Selecionar Todos
      </Paper>
      <Select
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        displayEmpty
        sx={{ mb: 2, mr: 2 }}
      >
        <MenuItem value="all">Todos</MenuItem>
        <MenuItem value="hidden">Escondidos</MenuItem>
        <MenuItem value="visible">Não Escondidos</MenuItem>
      </Select>
      <TextField
        label="Filtrar por Placa"
        value={plateFilter}
        onChange={(e) => setPlateFilter(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  inputProps={{ 'aria-label': 'Selecionar Todos' }}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Link do Vídeo</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVehicles.map((vehicle) => (
              <TableRow key={vehicle.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedVehicles.includes(vehicle.id)}
                    onChange={() => handleSelectVehicle(vehicle.id)}
                  />
                </TableCell>
                <TableCell>{vehicle.id}</TableCell>
                <TableCell>{vehicle.plate}</TableCell>
                <TableCell>{vehicle.model}</TableCell>
                <TableCell>{vehicle.hidden ? 'Escondido' : 'Visível'}</TableCell>
                <TableCell>{vehicle.video_link}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEditOpen(vehicle)}>
                    Editar Link
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir os veículos selecionados? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteVehicles} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Editar Link do Vídeo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Link do Vídeo"
            type="text"
            fullWidth
            value={editLink}
            onChange={(e) => setEditLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditSubmit} color="secondary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GerenciadorVeicular;
