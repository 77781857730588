import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Alert,
  Grid
} from '@mui/material';
import { styled } from '@mui/system';
import logo from './img/logo.png'; // Certifique-se de que o caminho para a logo está correto

const StyledContainer = styled(Container)({
  height: '100vh',
  backgroundColor: '#1c1c1c', // Cor de fundo uniforme
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  padding: '0 20px'
});

const SignupPaper = styled(Paper)({
  padding: '40px',
  textAlign: 'center',
  maxWidth: '400px',
  width: '100%', // Garante que o papel ocupe 100% da largura disponível
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  backgroundColor: 'white',
  marginTop: '20px',
  marginBottom: '20px'
});

const PromoBox = styled(Box)({
  padding: '20px',
  textAlign: 'center',
  color: 'white'
});

const Logo = styled('img')({
  width: '150px', // Ajuste conforme necessário
  height: 'auto',
  marginBottom: '20px'
});

const PromoText = styled(Typography)({
  marginBottom: '20px',
  color: 'white',
  fontWeight: '700',
  lineHeight: '1.5'
});

const Highlight = styled('span')({
  color: '#ffcc00' // Cor destaque (amarelo)
});

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '20px'
});

const HeaderText = styled(Typography)({
  marginLeft: '10px',
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#ffffff'
});

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    name: '',
    phoneNumber: '',
    email: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validações frontend
    if (formData.username.length > 10) {
      setError('O nome de usuário deve ter no máximo 10 caracteres.');
      return;
    }
    if (formData.password.length > 20) {
      setError('A senha deve ter no máximo 20 caracteres.');
      return;
    }
    if (formData.name.length > 20) {
      setError('O nome deve ter no máximo 20 caracteres.');
      return;
    }
    if (formData.phoneNumber.length > 20 || !/^\d+$/.test(formData.phoneNumber)) {
      setError('O número de telefone deve conter apenas números e ter no máximo 20 caracteres.');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email.length > 30 || !emailPattern.test(formData.email)) {
      setError('O email deve ser válido e ter no máximo 30 caracteres.');
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, formData);
      if (response.data.error) {
        setError(response.data.error); // Exibe o erro retornado pelo backend
      } else {
        setSuccess(true);
        setTimeout(() => navigate('/login'), 10000);
      }
    } catch (err) {
      setError('Erro ao criar a conta. Tente novamente.');
    }
  };

  return (
    <StyledContainer maxWidth="lg">
      <PromoBox>
        <Header>
          <Logo src={logo} alt="Logo da Empresa" />
          <HeaderText></HeaderText>
        </Header>
        <PromoText variant="h3">
          
        </PromoText>
      </PromoBox>
      <SignupPaper>
        <Typography variant="h4" gutterBottom>
          Criar Conta
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nome de usuário (Máx. 10 caracteres)"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 10 }}
          />
          <TextField
            label="Senha (Máx. 20 caracteres)"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            label="Confirme a Senha"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            label="Nome (Máx. 20 caracteres)"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            label="Telefone (Apenas números, Máx. 20 caracteres)"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            label="Email (Máx. 30 caracteres)"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 30 }}
          />
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mt: 2 }}>Conta criada com sucesso, aguarde a ativação!</Alert>}
          <Box sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Criar Conta
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" color="primary" fullWidth onClick={() => navigate('/login')}>
              Voltar
            </Button>
          </Box>
        </form>
      </SignupPaper>
      <PromoBox>
        <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '20px' }}>
          
        </Typography>        
      </PromoBox>
    </StyledContainer>
  );
};

export default Signup;
